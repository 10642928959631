/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.card-img-overlay-shadow {
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 26%, #fff 87%);
}

.exercise-answer-dot {
  border-radius: 50%;
  height: 4px;
  width: 4px;
  background: "grey";
  margin: 5px auto;
}

.exercise-answer-dot-selected {
  height: 8px;
  width: 8px;
  background: #97cdfe;
}

.exercise-answer-dot-wrapper {
  width: min-content;
}

.exercise-textselect {
  border-bottom: 1px solid rgba(0, 0, 0, .33);
  overflow: hidden;
  padding-right: 1em;
  margin-right: .17em;
  cursor: pointer;
  position: relative;

  &::after {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      content: '';
      height: 0;
      width: 0;
      border: .33em solid transparent;
      border-top: .33em solid rgba(0, 0, 0, .33);
      margin-top: -.17em;
  }

  &-input {
      font-size: 1.2em;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      opacity: 0;
      z-index: 1;
  }

  &.bright {
    &::after {
        border-top: .33em solid rgba(255, 255, 255, .33);
    }
  }
}

/* React Select -- Inline Modifier
* Inline container with margin for better inline spacing between other elements 
*/
.react-select--inline {
  display: inline-block;
  margin: 0 0.25em;
}

/* Remove border, outline, box-shadow, and min-height values */
.react-select--inline .react-select__control {
  border: none;
  outline: none;
  box-shadow: none;
  min-height: none;
  cursor: pointer;
}

/* Tighten up spacing */
.react-select--inline .react-select__value-container {
  padding: 0;
}

/* Position value relative (instead of absolute) and remove transform and max-width */
.react-select--inline .react-select__single-value {
  font-weight: bold;
  position: relative;
  transform: none;
  max-width: none;
}

/* CSS triangle dropdown indicator after the selected value */
.react-select--inline .react-select__single-value::after {
  content: " ";
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  border-color: #000000 transparent transparent transparent;

  margin-left: 0.25em;
  top: -0.125em;
}

.class-irregular-verbs-button-open {
  position: fixed;
  bottom: 50%;
  right: 30px;
  display: none;
  z-index: 99;
}

.class-homework-card-open {
  position: fixed;
  bottom: 1%;
  right: 50%;
  display: none;
  z-index: 99;
}

// .card-img-overlay-shadow:hover {
// 	opacity: 75%;
//   transition: opacity 0.2s ease-in-out;
// 	position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: #0d0d33;
//   z-index: 2;
//   opacity: 0%;
//   transition: opacity 0.2s ease-in-out;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

